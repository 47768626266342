import React, { Component } from 'react';
import { notification } from 'antd';
import {default as ItemDetail} from './Detail';
import Button from 'components/new/Button';
import Modal from 'components/Modal';
import Upload from 'components/Upload';
import Tab from 'common/Tab';
import Pill from 'components/Pill';
import TabGroup from 'common/TabGroup';
import Accordion from 'components/Accordion';
import Gallery from 'components/Gallery.component';
import EditTextField from 'components/EditTextField.component';
import TextField from 'components/TextField';
import Pencil from 'assets/pencil.svg';
import Document from 'assets/icons/document-purple.svg';
import Close from 'assets/icons/Close';
import NoEventLogo from 'assets/noLogo.png';
import EventSidePanel from 'screens/event/components/eventsidepanel.component';
import EventGallery from 'screens/event/components/eventgallery.component';
import EventAudienceTab from 'screens/event/components/eventaudiencetab.component';
import EditEventOverviewModal from './components/editeventoverviewmodal.component';
import EditEventAudienceModal from './components/editeventaudiencemodal.component';
//import EditEventPackageModal from './components/new/editeventpackagemodal.component';
import EditEventPackageModal from './components/editeventpackagemodal.component';

import { searchPlacements, setPlacements } from 'services/placements/placements.actions';
import { postPackages, putPackages, addPackage, removePackage } from 'services/packages/packages.actions';
import { postEventAudienceProfile, putEventAudienceProfile } from 'services/eventaudienceprofile/eventaudienceprofile.actions';
import { putEventAttendances } from 'services/eventattendances/eventattendances.actions';
import { putEventGenders } from 'services/eventgenders/eventgenders.actions';
import { postEventAssets, deleteEventAsset } from 'services/eventassets/eventassets.actions';
import { addAudienceProfileAttribute } from 'services/audienceprofile/audienceprofile.actions';
import { getPackageCategories } from 'services/packagecategories/packagecategories.actions';
import { getPackageTypes } from 'services/packagetypes/packagetypes.actions';
import { getPlacementTypes } from 'services/placementtypes/placementtypes.actions';
import { bindActionCreators } from 'redux';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { getEvent, putEvent } from 'services/events/events.actions';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import './edit.css';

const AssetTypes = {
  photos: 1,
  video: 2,
  logo: 3,
  prospectus: 4,
  pitchDeck: 5
}

class EditEvent extends Component {
  constructor(props) {
    super(props);

    let { assets } = props.event;
    let video = this.getAssetByAssetTypeId(assets, AssetTypes.video);
    let prospectus = this.getAssetByAssetTypeId(assets, AssetTypes.prospectus);
    let decks = this.getAssetByAssetTypeId(assets, AssetTypes.pitchDeck);

    this.state = {};

    for (let key in props.event.audienceAttributes) {
      props.event.audienceAttributes[key].forEach((attribute) => props.addAudienceProfileAttribute(attribute));
    }

    let pdfdescription = "";
    if (prospectus.length > 0) {
      pdfdescription = prospectus[0].description;
    }

    let deckdescription = "";
    if (decks.length > 0) {
      deckdescription = decks[0].description;
    }

    this.state = {
      description: {
        value: props.event.event.description,
        error: false
      },
      audience_description: {
        value: props.event.event.audience_description || "",
        error: false
      },
      organization_description: {
        value: props.event.event.organization_description || "",
        error: false
      },
      pdfdescription: {
        value: pdfdescription,
        error: false
      },
      deckdescription: {
        value: deckdescription,
        error: false
      },
      tabs: {
        overview: {
          active: true,
          label: "Overview"
        },
        audience: {
          active: false,
          label: "Audience"
        },
        sponsorships: {
          active: false,
          label: "Sponsorships"
        }
      },
      refresh: false,
      isGalleryModalOpen: false,
      isEditOverviewModalOpen: false,
      isEditAudienceModalOpen: false,
      isEditPackageModalOpen: false,
      selectedPackage: null,
      asset_id_removed: 0,
      asset_filename_added: "",
      pdfFile: null,
      deckFile: null
    }
  }

  componentDidUpdate(prevProps) {

    if (!prevProps.event.event.id) return;


    let prevAttendanceAttributes = [];
    let currentAttendanceAttributes = [];

    if (prevProps.eventgenders !== null && this.props.eventgenders !== null) {
      if (((prevProps.eventgenders.female !== this.props.eventgenders.female) || (prevProps.eventgenders.non_binary !== this.props.eventgenders.non_binary) || (prevProps.eventgenders.male !== this.props.eventgenders.male))) {
        this.props.getEvent(null, this.props.event.event.id);
        this.setState({
          isEditAudienceModalOpen: false
        }, () => {
          notification.success({
            message: "Gender information has been updated."
          });
        });
      }
    } else if (prevProps.event.eventgenders === null && this.props.eventgenders !== null) {
      if (((prevProps.event.eventgenders.female !== this.props.eventgenders.female) || (prevProps.event.eventgenders.non_binary !== this.props.eventgenders.non_binary) || (prevProps.event.eventgenders.male !== this.props.eventgenders.male))) {
        this.props.getEvent(null, this.props.event.event.id);
        this.setState({
          isEditAudienceModalOpen: false
        }, () => {
          notification.success({
            message: "Gender information has been updated."
          });
        });
      }
    }

    if (prevProps.eventattendances !== null) {
      prevAttendanceAttributes = prevProps.eventattendances.map((item)=>`${item.year}${item.attendance}`)
    }

    if (this.props.eventattendances !== null) {
      currentAttendanceAttributes = this.props.eventattendances.map((item)=>`${item.year}${item.attendance}`)
    }

    if (prevAttributes !== null && !isEqual(currentAttendanceAttributes, prevAttendanceAttributes)) {
      this.props.getEvent(null, this.props.event.event.id);
      this.setState({
        isEditAudienceModalOpen: false
      }, () => {
        notification.success({
          message: "Attendance information has been updated."
        });
      });
    }

    let prevAttributes = [];
    let currentAttributes = [];

    if (this.props.eventaudienceprofile !== null) {
      currentAttributes = this.props.eventaudienceprofile.audience_profile.audience_attributes.map((attribute)=>`${attribute.type}${attribute.value}`)
    }

    if (prevProps.eventaudienceprofile !== null) {
      prevAttributes = prevProps.eventaudienceprofile.audience_profile.audience_attributes.map((attribute)=>`${attribute.type}${attribute.value}`)
    } else {
      prevAttributes = null;
    }

    if (prevAttributes !== null && !isEqual(currentAttributes, prevAttributes)) {
      this.props.getEvent(null, this.props.event.event.id);
      this.setState({
        isEditAudienceModalOpen: false
      }, () => {
        notification.success({
          message: "Audience information has been updated."
        });
      });
      return;
    }

    if (this.props.assetsRemoved.includes(this.state.asset_id_removed) && this.state.asset_id_removed !== 0) {
      this.setState({
        asset_id_removed: 0
      }, () => {
        this.props.getEvent(null, this.props.event.event.id);
        notification.success({
          message: "Asset has been removed."
        })
      });
      return;
    }

    if (this.props.assetsAdded.includes(this.state.asset_filename_added) && this.state.asset_filename_added !== "") {
      this.setState({
        asset_filename_added: ""
      }, () => {
        this.props.getEvent(null, this.props.event.event.id);
        notification.success({
          message: "Asset has been added."
        })
      });
      return;
    }

    let changed = [];

    if (this.props.event.event !== null && prevProps.event.event !== null) {
      if (this.props.event.event.id !== prevProps.event.event.id) return;
    }


    if (this.props.event.location.id !== prevProps.event.location.id) {
      changed.push("venue");
    }

    if (this.props.event.event.name !== prevProps.event.event.name) {
      changed.push("event name");
    }

    if (this.props.event.event.description !== prevProps.event.event.description) {
      changed.push("description");
    }

    if (this.props.event.event.audience_description !== prevProps.event.event.audience_description) {
      changed.push("audience description");
    }

    if (this.props.event.event.organization_description !== prevProps.event.event.organization_description) {
      changed.push("organization description");
    }

    if (this.props.event.event.website !== prevProps.event.event.website) {
      changed.push("website");
    }

    if (this.props.event.event.tagline !== prevProps.event.event.tagline) {
      changed.push("tagline");
    }

    if (this.props.event.event.startdate !== prevProps.event.event.startdate) {
      changed.push("start date");
    }

    if (this.props.event.event.enddate !== prevProps.event.event.enddate) {
      changed.push("end date");
    }

    if (this.props.event.event.expected_attendees !== prevProps.event.event.expected_attendees) {
      changed.push("expected attendees");
    }


    if (changed.length === 0) return;

    let message;

    if (changed.length > 2) {
      message = `${changed.join(", ")} were updated.`
      message.replace(`${changed[changed.length-1]} were updated.`, ` and ${changed[changed.length-1]} were updated.`)
      message = message.charAt(0).toUpperCase() + message.substring(1);
    } else if (changed.length === 1) {
      message = `${changed.join(" ")} has been updated.`
      message = message.charAt(0).toUpperCase() + message.substring(1);
    } else if (changed.length === 2) {
      message = `${changed[0]} and ${changed[1]} have been updated.`
      message = message.charAt(0).toUpperCase() + message.substring(1);
    }

    notification.success({
      message
    })
    this.setState({
      isEditOverviewModalOpen: false,
      isEditAudienceModalOpen: false
    })
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getEvent(null, id);
    this.props.getPackageCategories();
    this.props.getPackageTypes();
    this.props.getPlacementTypes();
  }

  setModalVisible(isVisible, key) {
    this.setState({
      [key]: isVisible
    })
  }

  activate(current) {
    let { tabs } = this.state;
    let update = {...tabs};

    for (let tab in tabs) {
      if (current === tab) {
        update[tab].active = true;
      } else {
        update[tab].active = false;
      }
    }

    this.setState({
      tabs: {...update}
    });
  }

  getAssetByAssetTypeId(assets, asset_type_id) {
    let { event } = this.props;

    if (!event) {
      return [];
    }

    if (!event.assets) {
      return [];
    }

    assets = event.assets;

    let items = assets.filter((asset) => {
      return asset.asset_type_id === asset_type_id
    }).map(({id, description, url}) => {
      return {
        id,
        description,
        src: url
      }
    });
    return items;
  }

  setAudienceTab() {
    if (!this.state.tabs.audience.active) return (null);
    let { audienceAttributes, event_id, eventAttendances } = this.props.event;
    return (
      <div style={{position: 'relative'}}>
        <div className="edit-tab-pencil">
          <img className="edit-modal-open" alt="A pencil, that when clicked, will open a modal to allow for audience information to be edited." onClick={this.setModalVisible.bind(this, true, "isEditAudienceModalOpen")} src={Pencil} />
        </div>
        <EventAudienceTab
          expected_attendees={this.props.event.event.expected_attendees}
          audience_attributes={audienceAttributes}
          eventGender={this.props.event.eventGender}
          eventAttendances={eventAttendances}
        />
      </div>

    )
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  save(field) {
    if (this.props.event.event[field] === this.state[field].value) return;

    let event = {
      ...this.props.event.event,
      [field]: this.state[field].value
    };

    this.props.putEvent(event);
  }

  handleSubmitAsset(asset_type_id, event, files) {
    this.addAsset(files, asset_type_id);
  }

  handleSubmitPDF(event, files) {
    this.setState({
      pdfFile: files
    })
  }

  handleSubmitDeck(event, files) {
    this.setState({
      deckFile: files
    })
  }

  onClickSubmitDeck() {
    if (this.state.deckFile === null) {
      notification.error({
        message: "Please select a file to upload."
      })
      return;
    }
    this.addAsset(this.state.deckFile, AssetTypes.pitchDeck, this.state.deckdescription.value);
  }

  onClickSubmitProspectus() {
    if (this.state.pdfFile === null) {
      notification.error({
        message: "Please select a file to upload."
      })
      return;
    }
    this.addAsset(this.state.pdfFile, AssetTypes.prospectus, this.state.pdfdescription.value);
  }

  addAsset(files, asset_type_id, description = "") {
    let assets = [];

    Array.from(files).forEach((file) => {
      const data = new FormData();
      data.append('file', file);
      data.append('filename', `${file.name} asset_type_id:${asset_type_id}`);
      data.append("asset_type_id", asset_type_id);
      data.append("event_id", this.props.event.event.id);
      data.append("description", description)
      assets.push(data);
    });

    let asset = assets[0];

    this.setState({
      asset_filename_added: `${asset.get("filename")}`
    }, () => {
      this.props.postEventAssets(asset);
    })
  }

  removeAsset(id) {
    this.setState({
      asset_id_removed: id
    },() => {
      this.props.deleteEventAsset(id)
    })
  }

  clearPDFFile() {
    this.setState({
      pdfFile: null
    })
  }

  clearDeckFile() {
    this.setState({
      deckFile: null
    })
  }

  setOverviewTab() {
    if (!this.state.tabs.overview.active) return (null);

    let { audience_description, organization_description, description } = this.state;

    let { assets } = this.props.event;
    let video = this.getAssetByAssetTypeId(assets, AssetTypes.video);
    let prospectus = this.getAssetByAssetTypeId(assets, AssetTypes.prospectus);
    let decks = this.getAssetByAssetTypeId(assets, AssetTypes.pitchDeck);

    let videoElement, pdfElement, pitchDeckElement = null;

    if (video.length > 0) {
      videoElement = (
        <div className="d-flex flex-column justify-content-center event-video-container">
          <video height="auto" width="100%" controls>
            <source src={video[0].src} type="video/mp4" />
          </video>
          <div className="d-flex justify-content-end" style={{marginTop: '15px'}}>
            <Button
              label="Remove"
              type="tertiary"
              onClick={this.removeAsset.bind(this, video[0].id)} />
          </div>
        </div>
      )
    }  else {
      videoElement = (
        <Upload multiple={false} accept="video/*" label="Event Video" onSubmit={this.handleSubmitAsset.bind(this, AssetTypes.video)} />
      )
    }

    if (prospectus.length > 0) {
      pdfElement = (
        <div className="d-flex flex-row align-items-center event-overview-document-row">
          <a style={{cursor: "pointer", width: '100%'}} target="_blank" href={prospectus[0].src} rel="noopener noreferrer" className="d-flex flex-row align-items-center"> {/*event-overview-document-row*/}
            <img alt="" src={Document} style={{height: '40px', marginRight: '35px'}} />
            <div>
              <div style={{marginBottom: '5px'}} className="body-copy-bold-tertiary">Conference Prospectus</div>
              <div className="body-copy-tertiary">{prospectus[0].description}</div>
            </div>
          </a>
          <div>
            <Button
              label="Remove"
              type="tertiary"
              onClick={this.removeAsset.bind(this, prospectus[0].id)} />
          </div>
        </div>
      )
    } else {
      if (this.state.pdfFile !== null) {
        pdfElement = (
          <div className="event-overview-document-row">
            <div className="body-copy-tertiary justify-content-between d-flex align-items-center" style={{paddingRight: '7px', paddingTop: '10px', paddingBottom: '10px'}}>
              <div>{Array.from(this.state.pdfFile)[0]["name"]}</div>
              <div className="d-flex"><Close onClick={this.clearPDFFile.bind(this)} /></div>
            </div>
            <TextField
              fontStyle="tertiary"
              name="pdfdescription"
              placeholder="Conference prospectus description"
              value={this.state.pdfdescription.value}
              errorMessage="Conference prospectus description."
              error={this.state.pdfdescription.error}
              onChange={this.onChange.bind(this)}
              label="Conference prospectus description"
              size="full" />
            <div style={{marginTop: '25px'}}>
              <Button
                label="Add Prospectus"
                type="tertiary"
                onClick={this.onClickSubmitProspectus.bind(this)} />
            </div>
          </div>
        )
      } else {
        pdfElement = (
          <div className="event-overview-document-row">
            <Upload accept=".pdf,.ppt,.pps,.pptx,.ppsx,.pot,.potx,.doc,.docx,.rtf" style={{paddingTop: '15px', marginBottom: '25px'}} multiple={false} label="Conference Prospectus" onSubmit={this.handleSubmitPDF.bind(this)} />
            <TextField
              fontStyle="tertiary"
              name="pdfdescription"
              placeholder="Conference prospectus description"
              value={this.state.pdfdescription.value}
              errorMessage="Conference prospectus description."
              error={this.state.pdfdescription.error}
              onChange={this.onChange.bind(this)}
              label="Conference prospectus description"
              size="full" />
            <div style={{marginTop: '25px'}}>
              <Button
                label="Add Prospectus"
                type="tertiary"
                onClick={this.onClickSubmitProspectus.bind(this)} />
            </div>
          </div>
        )
      }
    }

    if (decks.length > 0) {
      pitchDeckElement = (
        <div className="d-flex flex-row align-items-center event-overview-document-row">
          <a style={{cursor: "pointer", width: '100%'}} target="_blank" rel="noopener noreferrer" href={decks[0].src} className="d-flex flex-row align-items-center"> {/*event-overview-document-row*/}
            <img alt="" src={Document} style={{height: '40px', marginRight: '35px'}} />
            <div>
              <div style={{marginBottom: '5px'}} className="body-copy-bold-tertiary">Pitch deck</div>
              <div className="body-copy-tertiary">{decks[0].description}</div>
            </div>
          </a>
          <div>
            <Button
              label="Remove"
              type="tertiary"
              onClick={this.removeAsset.bind(this, decks[0].id)} />
          </div>
        </div>
      )
    } else {
      if (this.state.deckFile !== null) {
        pitchDeckElement = (
          <div className="event-overview-document-row">
            <div className="body-copy-tertiary justify-content-between d-flex align-items-center" style={{paddingRight: '7px', paddingTop: '10px', paddingBottom: '10px'}}>
              <div>{Array.from(this.state.deckFile)[0]["name"]}</div>
              <div className="d-flex"><Close onClick={this.clearPDFFile.bind(this)} /></div>
            </div>
            <TextField
              fontStyle="tertiary"
              name="deckdescription"
              placeholder="Pitch deck description"
              value={this.state.deckdescription.value}
              errorMessage="Pitch deck description."
              error={this.state.deckdescription.error}
              onChange={this.onChange.bind(this)}
              label="Pitch deck description"
              size="full" />
            <div style={{marginTop: '25px'}}>
              <Button
                label="Add Deck"
                type="tertiary"
                onClick={this.onClickSubmitDeck.bind(this)} />
            </div>
          </div>
        )
      } else {
        pitchDeckElement = (
          <div className="event-overview-document-row">
            <Upload accept=".pdf,.ppt,.pps,.pptx,.ppsx,.pot,.potx,.doc,.docx,.rtf" style={{paddingTop: '15px', marginBottom: '25px'}} multiple={false} label="Pitch Deck" onSubmit={this.handleSubmitDeck.bind(this)} />
            <TextField
              fontStyle="tertiary"
              name="deckdescription"
              placeholder="Pitch deck description"
              value={this.state.deckdescription.value}
              errorMessage="Pitch deck description."
              error={this.state.deckdescription.error}
              onChange={this.onChange.bind(this)}
              label="Pitch deck description"
              size="full" />
            <div style={{marginTop: '25px'}}>
              <Button
                label="Add Deck"
                type="tertiary"
                onClick={this.onClickSubmitDeck.bind(this)} />
            </div>
          </div>
        )
      }
    }

    return (
      <div className="edit-overview-tab event-details-panel">
        <EditTextField
          isTextArea={true}
          value={description.value}
          onChange={this.onChange.bind(this)}
          label="About Event"
          name="description"
          onShowTextField={this.save.bind(this, "description")}/>
        {videoElement}
        <EditTextField
          isTextArea={true}
          value={organization_description.value}
          onChange={this.onChange.bind(this)}
          label="About Organization"
          name="organization_description"
          onShowTextField={this.save.bind(this, "organization_description")}/>
        <EditTextField
          isTextArea={true}isTextArea={true}
          value={audience_description.value}
          onChange={this.onChange.bind(this)}
          label="About Audience"
          name="audience_description"
          onShowTextField={this.save.bind(this, "audience_description")}/>
        <div>
          <div className="header-3-tertiary" style={{marginBottom: '8px'}}>Documents</div>
          <div className="event-overview-documents">
            {pdfElement}
            {pitchDeckElement}
          </div>
        </div>
      </div>
    )
  }

  setPackagesTab(packages) {
    if (!this.state.tabs.sponsorships.active) return (null);
    if (packages === null) return (null);
    let panels = [];

    packages.map((item, index) => {
      let { name, cost, limit, placements, description } = item;

      let content = () => {
        return (
          <div key={index} className="event-details-packages-content">
            <ItemDetail label="Description" value={description} />
            {placements ? <ItemDetail label="Package Contents" value={(placements.length === 0 ? "None" : placements.map((placement) => placement.placement.placement).join(", "))} /> : null}
            <ItemDetail label="# of Available Packages" value={limit} />
          </div>
        )
      }

      let panel = {
        label: (
          <div className="d-flex flex-row">
            <div>
              <div className='body-copy-bold-brand'>{name}</div>
              <div className="body-copy-tertiary">{generateCurrencyFormat(cost)}</div>
            </div>
          </div>
        ),
        content
      }

      panels.push(panel);
    });

    return (
      <div style={{position: 'relative'}}>
        <div style={{display: 'none'}} className="edit-tab-pencil">
          <img alt="A pencil, that when clicked, will open a modal to allow for package information to be edited." className="edit-modal-open" onClick={this.setModalVisible.bind(this, true, "isEditPackageModalOpen")} src={Pencil} />
        </div>
        <div className="event-details-panel">
          <Accordion panels={panels} />
        </div>
      </div>
    )
  }

  getTabs() {
    let buffer = [];
    let index = 0;

    for (let tab in this.state.tabs) {

      let { label, active, hidden } = this.state.tabs[tab];

      buffer.push(
        <Tab
          type="bold"
          isCompleteVisible={false}
          key={index}
          hidden={hidden}
          label={label}
          selected={active}
          onClick={this.activate.bind(this, tab)} />
        )
      index++;
    }
    return buffer;
  }

  getEventAudienceProfileData() {
    let eap = {};

    eap.event_id = this.props.event.event.id;
    eap.audience_attributes = this.props.attributes;

    return eap;
  }

  onClickSaveEventAudience() {
    let data = this.getEventAudienceProfileData();

    if (this.props.event.audienceAttributes === null) {
      this.props.postEventAudienceProfile(data);
      return;
    }
    this.props.putEventAudienceProfile(data);
  }

  render() {
    if (!this.props.event.event.id) return null;
    let { packages } = this.props;
    let { event, audienceAttributes, assets, organization } = this.props.event;
    let { location } = event;
    let { tabs, website, tagline, name, place_id, place_name, startdate, enddate } = event;

    if (!location) return null;
    let images = this.getAssetByAssetTypeId(assets, AssetTypes.photos);
    let logos = this.getAssetByAssetTypeId(assets, AssetTypes.logo);

    if (this.props.event.location) {
      location = this.props.event.location;
    }

    let place_description = (location.description ? location.description : `${location.name}, ${location.formatted_address}`)

    let logoElement = <img alt="" style={{height: '175px'}} src={NoEventLogo} />

    if (logos.length > 0) {
      logoElement = <img alt="Event or company logo" style={{width: '80%' }} src={logos[0].src} />
    }

    let packagecategories = this.props.packagecategories.map(({id, category}) => {
      return {value: id, label: category};
    });

    let packagetypes = this.props.packagetypes.map(({id, type}) => {
      return {value: id, label: type};
    });

    let placementtypes = this.props.placementtypes.map(({id, type}) => {
      return {value: id, label: type};
    });
    console.log(this.props.event.event, "uh ha ha ha");
    return (
      <div style={{paddingBottom: '60px', paddingTop: '25px'}}>
        <div>
          <div className="d-flex flex-md-row flex-column">
            <EventSidePanel
              location={location}
              logos={logos}
              website={website}
              tagline={tagline}
              name={name}
              startdate={startdate}
              enddate={enddate}
              assets={assets}
              venuename={location.name}
              venueaddress={location.formatted_address}
              organization={organization}>
                <div style={{right: '15px', top: '15px', position: 'absolute'}}>
                  <img alt="A pencil, that when clicked, will open a modal to allow for event details to be edited." className="edit-modal-open" onClick={this.setModalVisible.bind(this, true, "isEditOverviewModalOpen")} src={Pencil} />
                </div>
            </EventSidePanel>
            <div className="event-main-panel col-md-8 col-12 event-details-main flex-shrink-0 d-flex flex-column">
              <EventGallery images={images}/>
              <div className="d-flex flex-column event-tab-group">
                <div>
                  <TabGroup>
                    {this.getTabs()}
                  </TabGroup>
                </div>
                <div style={{height: '100%'}}>
                  { this.setOverviewTab() }
                  { this.setPackagesTab(packages) }
                  { this.setAudienceTab(audienceAttributes) }
                </div>
              </div>
            </div>
          </div>
          <EditEventOverviewModal
            isEditModalOpen={this.state.isEditOverviewModalOpen}
            event={event}
            location={location}
            assets={assets}
            logos={logos}
            removeAsset={this.removeAsset.bind(this)}
            putEvent={this.props.putEvent.bind(this)}
            onClickSubmitLogo={this.handleSubmitAsset.bind(this, AssetTypes.logo)}
            onClickClose={this.setModalVisible.bind(this, false, "isEditOverviewModalOpen")} />
          <EditEventAudienceModal
            event={this.props.event.event}
            eventGender={this.props.event.eventGender}
            eventAttendances={this.props.event.eventAttendances}
            isEditModalOpen={this.state.isEditAudienceModalOpen}
            expected_attendees={this.props.event.event.expected_attendees}
            audience_profile_name={this.props.event.audienceProfileName}
            onClickSaveEventAudience={this.onClickSaveEventAudience.bind(this)}
            putEvent={this.props.putEvent.bind(this)}
            putEventGenders={this.props.putEventGenders.bind(this)}
            putEventAttendances={this.props.putEventAttendances.bind(this)}
            onClickClose={this.setModalVisible.bind(this, false, "isEditAudienceModalOpen")} />
          <EditEventPackageModal
            isEditModalOpen={this.state.isEditPackageModalOpen}
            packagecategories={packagecategories}
            packagetypes={packagetypes}
            placementtypes={placementtypes}
            removePackage={this.props.removePackage.bind(this)}
            postPackages={this.props.postPackages.bind(this)}
            putPackages={this.props.putPackages.bind(this)}
            addPackage={this.props.addPackage.bind(this)}
            searchPlacements={this.props.searchPlacements.bind(this)}
            setPlacements={this.props.setPlacements.bind(this)}
            placements={this.props.placements}
            event={this.props.event.event}
            packages={packages}
            onClickSave={(()=>{})}
            onClickClose={this.setModalVisible.bind(this, false, "isEditPackageModalOpen")} />

          {/*<EditEventPackageModal
            isEditModalOpen={this.state.isEditPackageModalOpen}
            packagecategories={packagecategories}
            packagetypes={packagetypes}
            placementtypes={placementtypes}
            onClickSave={(()=>{})}
            onClickClose={this.setModalVisible.bind(this, false, "isEditPackageModalOpen")} />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attributes: state.audienceprofile.attributes,
    event: state.events.currentEvent,
    items: state.cart.items,
    user: state.users.user,
    assets: state.eventassets.assets,
    eventattendances: state.eventattendances.eventattendances,
    eventgenders: state.eventgenders.eventgenders,
    assetsRemoved: state.eventassets.assetsRemoved,
    assetsAdded: state.eventassets.assetsAdded,
    eventaudienceprofile: state.eventaudienceprofile.event_audience_profile,
    packagecategories: state.packagecategories.categories,
    packagetypes: state.packagetypes.types,
    placementtypes: state.placementtypes.types,
    placements: state.placements.placements,
    packages: state.packages.packages,
    savedPackages: state.packages.savedPackages,
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getEvent,
      putEvent,
      addAudienceProfileAttribute,
      putEventAttendances,
      putEventGenders,
      postEventAssets,
      deleteEventAsset,
      putEventAudienceProfile,
      postEventAudienceProfile,
      getPackageCategories,
      getPackageTypes,
      getPlacementTypes,

      searchPlacements,
      setPlacements,
      postPackages,
      putPackages,
      addPackage,
      removePackage,
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
