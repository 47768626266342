import React, { Component } from 'react';
import NavBarLink from './NavBarLink';
import Logo from 'assets/logo-white.svg';
import LogoWhitePurple from 'assets/logo-white-purple.svg';
import LogoPurple from 'assets/logo-purple.svg';
import User from 'assets/icons/user@3x.png';
import Button from 'components/new/Button';
import Detail from 'common/Detail';
import Divider from 'common/Divider.component';
import DownArrowFull from 'assets/down-arrow-full.svg';
import DownArrowFullPurple from 'assets/down-arrow-full-purple.svg';
import Modal from 'components/Modal';
import { Routes } from 'constants/Routes';
import { withRouter } from "react-router-dom";
import { Icon, Tooltip, Collapse, Menu, Dropdown } from 'antd';
import { userLogout } from 'services/auth/auth.actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import './NavBar.css';
const { Panel } = Collapse;

const {
  LANDING,
  ORGANIZER_HOME,
  SPONSOR_HOME,
  LOG_IN,
  LEARN_MORE_RESOURCES_BLOG,
  LEARN_MORE_RESOURCES_FAQS,
  ORGANIZER_ONBOARDING_INTRO,
  LEARN_MORE_EVENT_ORGANIZER,
  LEARN_MORE_SPONSOR,
  LEARN_MORE_RESOURCES,
  LEARN_MORE_RESOURCES_PRODUCT_DEMO
} = Routes;

const ROLES = {
  ADMIN: {
    USER_TYPE_ID: 1
  },
  EVENT_ORGANIZER: {
    USER_TYPE_ID: 2
  },
  SPONSOR: {
    USER_TYPE_ID: 3
  }
}
/*
const links = [
  {
    text: "Event Organizer",
    url: ORGANIZER_HOME,
    isTopNavUrl: true
  },
  {
    text: "Sponsor",
    url: SPONSOR_HOME,
    isTopNavUrl: true
  }
]*/

const links = [
  {
    text: "My Events",
    url: ORGANIZER_HOME,
    isTopNavUrl: true,
    userTypeId: ROLES.EVENT_ORGANIZER.USER_TYPE_ID
  },
  {
    text: "My Portfolios",
    url: SPONSOR_HOME,
    isTopNavUrl: true,
    userTypeId: ROLES.SPONSOR.USER_TYPE_ID
  }
]

const admin_links = [
  {
    text: "My Events",
    url: ORGANIZER_HOME,
    isTopNavUrl: true
  },
  {
    text: "My Portfolios",
    url: SPONSOR_HOME,
    isTopNavUrl: true
  }
]

const resources = {
  productDemo: "Learn more about how our product helps your event.",
  faqs: "See our response to frequently asked questions.",
  blog: "Level up! Read our blog to get tips for your events business."
}

const TRANSPARENT_ROUTES = [LANDING, LEARN_MORE_EVENT_ORGANIZER, LEARN_MORE_SPONSOR, LEARN_MORE_RESOURCES];

//const TRANSPARENT_ROUTES = [Routes.LANDING];

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedOut: false,
      isMenuOpen: false,
      icon: "plus"
    }
  }

  onClickToggleMenu(state) {
    this.setState({
      isMenuOpen: state
    })
  }

  getMenu() {
    return(
      <Menu>
        <Menu.Item onClick={this.props.userLogout.bind(this)}>
          <span className="body-copy-tertiary">Log out</span>
        </Menu.Item>
      </Menu>
    );
  }

  getNavBarLeft() {
    let { userData } = this.props;
    let { pathname } = this.props.history.location;
    let active_links = links;

    if (userData) {
      let skip = false;

      if (userData.user_type_id == 1) {
        skip = true;
        active_links = admin_links;
      }

      let type = "";
      let linkClass = "body-copy-primary"

      if (pathname === LANDING || pathname === LEARN_MORE_EVENT_ORGANIZER || pathname === LEARN_MORE_RESOURCES || pathname === LEARN_MORE_SPONSOR || pathname.includes("/blog")) {
        type="purple"
        linkClass = "body-copy-secondary"
      }
      return (
        <div className="d-flex flex-row align-items-center navbar-links">
          {
            active_links.filter((link) => link.isTopNavUrl).map((link, index) => {
              if (userData.user_type_id !== link.userTypeId && !skip) return null;
              return (<NavBarLink type={type} key={index} route={link.url} label={link.text} />);
            })
          }
          <NavBarLink type={type} route={LEARN_MORE_EVENT_ORGANIZER} label="Event Organizer" />
          <NavBarLink type={type} route={LEARN_MORE_SPONSOR} label="Sponsor"/>
          <Tooltip className="navbar-tooltip-resource-link" trigger="hover" placement="bottom" title={()=>{
            return(
              <div style={{padding: '15px'}}>
                <div className="navbar-tooltip-row d-flex flex-sm-row flex-column">
                  <a href={Routes.LEARN_MORE_RESOURCES_PRODUCT_DEMO} style={{cursor: "pointer", maxWidth: '270px'}}>
                    <div className="body-copy-bold-brand">Product Demo</div>
                    <div className="body-copy-tertiary">{resources.productDemo}</div>
                  </a>
                  <a href={Routes.LEARN_MORE_RESOURCES_BLOG} style={{cursor: "pointer", maxWidth: '270px'}}>
                    <div className="body-copy-bold-brand">Blog</div>
                    <div className="body-copy-tertiary">{resources.blog}</div>
                  </a>
                </div>
                <Divider type="brand secondary" style={{marginTop: '15px', marginBottom: '15px'}} />
                <div className="navbar-tooltip-row d-flex flex-sm-row flex-column">
                  <a href={Routes.LEARN_MORE_RESOURCES_FAQS} style={{cursor: "pointer", maxWidth: '270px'}}>
                    <div className="body-copy-bold-brand">FAQs</div>
                    <div className="body-copy-tertiary">{resources.faqs}</div>
                  </a>
                </div>
              </div>
            )
          }}>
          <div onClick={this.goToRoute.bind(this, Routes.LEARN_MORE_RESOURCES)} className="restage-link d-flex flex-row align-items-center">
            <div style={{cursor: "pointer", marginRight: '5px'}} className={`${linkClass}`}>Resources</div>
            {downArrow}
          </div>
          </Tooltip>
        </div>
      );
    }
    let downArrow = (<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 7.79999L13.0622 0.449987H0.937822L7 7.79999Z" fill="#E3DBE6"/>
        </svg>
    )
    let type = "";
    let linkClass = "body-copy-primary"

    if (pathname === LANDING || pathname === LEARN_MORE_EVENT_ORGANIZER || pathname === LEARN_MORE_RESOURCES || pathname === LEARN_MORE_SPONSOR || pathname.includes("/blog")) {
      type="purple"
      linkClass = "body-copy-secondary"
      downArrow = (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
        <path d="M7 7.79999L13.0622 0.449987H0.937822L7 7.79999Z" fill="#680594"/>
        </svg>)
    }

    return(
        <div className="d-flex flex-row align-items-center navbar-links">
          <NavBarLink type={type} route={LEARN_MORE_EVENT_ORGANIZER} label="Event Organizer" />
          <NavBarLink type={type} route={LEARN_MORE_SPONSOR} label="Sponsor"/>
          <Tooltip className="navbar-tooltip-resource-link" trigger="hover" placement="bottom" title={()=>{
            return(
              <div style={{padding: '15px'}}>
                <div className="navbar-tooltip-row d-flex flex-sm-row flex-column">
                  <a href={Routes.LEARN_MORE_RESOURCES_PRODUCT_DEMO} style={{cursor: "pointer", maxWidth: '270px'}}>
                    <div className="body-copy-bold-brand">Product Demo</div>
                    <div className="body-copy-tertiary">{resources.productDemo}</div>
                  </a>
                  <a href={Routes.LEARN_MORE_RESOURCES_BLOG} style={{cursor: "pointer", maxWidth: '270px'}}>
                    <div className="body-copy-bold-brand">Blog</div>
                    <div className="body-copy-tertiary">{resources.blog}</div>
                  </a>
                </div>
                <Divider type="brand secondary" style={{marginTop: '15px', marginBottom: '15px'}} />
                <div className="navbar-tooltip-row d-flex flex-sm-row flex-column">
                  <a href={Routes.LEARN_MORE_RESOURCES_FAQS} style={{cursor: "pointer", maxWidth: '270px'}}>
                    <div className="body-copy-bold-brand">FAQs</div>
                    <div className="body-copy-tertiary">{resources.faqs}</div>
                  </a>
                </div>
              </div>
            )
          }}>
          <div onClick={this.goToRoute.bind(this, Routes.LEARN_MORE_RESOURCES)} className="restage-link d-flex flex-row align-items-center">
            <div style={{cursor: "pointer", marginRight: '5px'}} className={`${linkClass}`}>Resources</div>
            {downArrow}
          </div>
          </Tooltip>
        </div>
    )
  }

  getNavBarRight() {
    let { userData, history } = this.props;
    let { pathname } = history.location;
    let type = (this.props.history.location.pathname === LANDING ? "link-brand-white" : "link-brand-white");
    if (userData) {
      let { first_name, last_name } = userData;
      return (
        <div className="d-flex flex-row align-items-center">
          <div key="link" onClick={this.goToRoute.bind(this, Routes.ORGANIZER_HOME)} className="link-brand-white">{first_name}</div>
          <Icon className="navigation-links-menu-right"  onClick={this.onClickToggleMenu.bind(this, true)} key="icon-navbar" style={{ marginLeft: '15px', cursor: 'pointer', color: "white", fontSize: '30px'}} type="menu" />
          <Dropdown overlay={this.getMenu()} placement="bottomLeft" trigger={['click']}>
            <img key="buffer-image" style={{ marginLeft: '15px', cursor: 'pointer' }} className="restage-user-link" alt="User icon" src={User} />
          </Dropdown>
        </div>
      )
    }

    if (TRANSPARENT_ROUTES.includes(pathname) || pathname.includes("/blog")) {

      let buttonType = "primary";
      let loginButtonType = "primary-border";
      let createEventClassName = "";
      let color = "#FFFFFF";

      if (pathname === LEARN_MORE_EVENT_ORGANIZER || pathname === LEARN_MORE_RESOURCES || LEARN_MORE_SPONSOR || pathname.includes("/blog")) {
        loginButtonType = "primary-border";
        buttonType = "primary";
        color = "#680894";
        if (pathname === LEARN_MORE_EVENT_ORGANIZER) {
          createEventClassName = "navbar-hidden"
        }
      }

      return (
        <div>
          <div className="navigation-links-right">
            <div className="d-flex align-items-center justify-content-end">
              <Button className={`${createEventClassName} button-text-medium create-event-button`} key="create-event-link" label="Create event" size="xs" type={buttonType} onClick={this.goToRoute.bind(this, Routes.ORGANIZER_ONBOARDING_INTRO)} />
              <Button className="button-text-medium" label="Log In" key="link-key" onClick={this.goToRoute.bind(this, LOG_IN)} type={loginButtonType} />
            </div>
          </div>
          <Icon className="navigation-links-menu-right" onClick={this.onClickToggleMenu.bind(this, true)} key="icon-navbar" style={{ marginLeft: '15px', cursor: 'pointer', color: "white", fontSize: '30px', color: color}} type="menu" />
        </div>
      )
    }

    return (
      <div>
        <div className="navigation-links-right">
          <div className="d-flex flex-row align-items-center">
            <Button className="button-text-medium" label="Log In" key="link-key" onClick={this.goToRoute.bind(this, LOG_IN)} type="senary" />
          </div>
        </div>
        <Icon className="navigation-links-menu-right" onClick={this.onClickToggleMenu.bind(this, true)} key="icon-navbar" style={{ marginLeft: '15px', cursor: 'pointer', color: "white", fontSize: '30px'}} type="menu" />
      </div>
    );
  }

  goToRoute(route) {
    this.props.history.push(route);
  }

  goToRouteAndCloseModal(route) {

    this.setState({
      isMenuOpen: false
    }, ()=>{
          this.goToRoute(route);
    })

  }

  getArrow() {
    return (
      <Icon
        type={this.state.icon}
        onClick={event => {
          // If you don't want click extra trigger collapse, you can prevent this:
        }}
      />
    );
  }

  setSubMenuToggle() {
    let { icon } = this.state;

    if (icon === 'plus') {
      icon = 'minus'
    } else {
      icon = 'plus'
    }

    this.setState({
      icon
    })
  }

  getNavBarMobileLinks() {
    let { userData } = this.props;

    if (userData) {
      return (
        <div className="navbar-mobile-links">
          {userData.user_type_id == ROLES.EVENT_ORGANIZER.USER_TYPE_ID ? [<a onClick={this.goToRouteAndCloseModal.bind(this, ORGANIZER_HOME)} className="body-copy-tertiary">My Events</a>, <Divider type="brand" style={{marginTop: '15px', marginBottom: '15px'}} />] : null}
          {userData.user_type_id == ROLES.EVENT_ORGANIZER.USER_TYPE_ID ? [<a onClick={this.goToRouteAndCloseModal.bind(this, SPONSOR_HOME)} className="body-copy-tertiary">My Portfolios</a>, <Divider type="brand" style={{marginTop: '15px'}} />] : null}
          {userData.user_type_id == ROLES.ADMIN.USER_TYPE_ID ? [<a onClick={this.goToRouteAndCloseModal.bind(this, ORGANIZER_HOME)} className="body-copy-tertiary">My Events</a>, <Divider type="brand" style={{marginTop: '15px', marginBottom: '15px'}} />] : null}
          {userData.user_type_id == ROLES.ADMIN.USER_TYPE_ID ? [<a onClick={this.goToRouteAndCloseModal.bind(this, SPONSOR_HOME)} className="body-copy-tertiary">My Portfolios</a>, <Divider type="brand" style={{marginTop: '15px'}} />] : null}
          <a onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_EVENT_ORGANIZER)} className="d-flex navbar-mobile-link body-copy-tertiary">Event Organizer</a>
          <Divider type="brand" />
          <a onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_SPONSOR)} className="d-flex navbar-mobile-link body-copy-tertiary">Sponsor</a>
          <Divider type="brand" />
          <Collapse style={{marginBottom: '15px'}} onChange={this.setSubMenuToggle.bind(this)} >
            <Panel className="navbar-resources-submenu body-copy-tertiary" showArrow={false} header="Resources" key="1" extra={this.getArrow()}>
              <div onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_RESOURCES_PRODUCT_DEMO)} style={{cursor: "pointer", maxWidth: '270px'}}>
                <div className="body-copy-bold-brand">Product Demo</div>
                <div className="body-copy-tertiary">{resources.productDemo}</div>
              </div>
              <div onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_RESOURCES_BLOG)} style={{cursor: "pointer", maxWidth: '270px'}}>
                <div className="body-copy-bold-brand">Blog</div>
                <div className="body-copy-tertiary">{resources.blog}</div>
              </div>
              <div onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_RESOURCES_FAQS)} style={{cursor: "pointer", maxWidth: '270px'}}>
                <div className="body-copy-bold-brand">FAQs</div>
                <div className="body-copy-tertiary">{resources.faqs}</div>
              </div>
            </Panel>
          </Collapse>
          <a onClick={this.props.userLogout.bind(this)} className="body-copy-tertiary">Log Out</a>
        </div>
      )
    }

    return (
      <div className="navbar-mobile-links">
        <a onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_EVENT_ORGANIZER)} className="d-flex navbar-mobile-link body-copy-tertiary">Event Organizer</a>
        <Divider type="brand" />
        <a onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_SPONSOR)} className="d-flex navbar-mobile-link body-copy-tertiary">Sponsor</a>
        <Divider type="brand" />
        <Collapse onChange={this.setSubMenuToggle.bind(this)} >
          <Panel className="navbar-resources-submenu body-copy-tertiary" showArrow={false} header="Resources" key="1" extra={this.getArrow()}>
            <div onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_RESOURCES_PRODUCT_DEMO)} style={{cursor: "pointer", maxWidth: '270px'}}>
              <div className="body-copy-bold-brand">Product Demo</div>
              <div className="body-copy-tertiary">{resources.productDemo}</div>
            </div>
            <div onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_RESOURCES_BLOG)} style={{cursor: "pointer", maxWidth: '270px'}}>
              <div className="body-copy-bold-brand">Blog</div>
              <div className="body-copy-tertiary">{resources.blog}</div>
            </div>
            <div onClick={this.goToRouteAndCloseModal.bind(this, LEARN_MORE_RESOURCES_FAQS)} style={{cursor: "pointer", maxWidth: '270px'}}>
              <div className="body-copy-bold-brand">FAQs</div>
              <div className="body-copy-tertiary">{resources.faqs}</div>
            </div>
          </Panel>
        </Collapse>
        <a onClick={this.goToRouteAndCloseModal.bind(this, ORGANIZER_ONBOARDING_INTRO)} className="d-flex navbar-mobile-link body-copy-tertiary">Create An Account</a>
        <Divider type="brand" />
        <a onClick={this.goToRouteAndCloseModal.bind(this, LOG_IN)} className="d-flex navbar-mobile-link body-copy-tertiary">Log In</a>
      </div>
    )
  }

  render() {
    let style = {};
    let classNames = ["navbar-container", "d-flex", "align-items-center", "justify-content-between"]
    let logo = Logo;

    let { pathname } = this.props.history.location
    if (TRANSPARENT_ROUTES.includes(pathname)) {
      classNames.push("navbar-container-landing");
    }

    if (pathname === LEARN_MORE_EVENT_ORGANIZER || pathname === LEARN_MORE_RESOURCES || pathname === LEARN_MORE_SPONSOR || pathname.includes("/blog")) {
      logo = LogoPurple;
      classNames.push("navbar-container-background-white");
    }

    if (pathname === LANDING) {
      logo = LogoPurple;
    }

    return (
      <div>
        <div style={style} className={classNames.join(" ")}>
          <div className="d-flex flex-row align-items-center">
            <div style={{marginRight: '25px'}}>
              <img className="navbar-logo"  onClick={this.goToRoute.bind(this, LANDING)} src={logo}/>
            </div>
            {this.getNavBarLeft()}
          </div>
          {this.getNavBarRight()}
        </div>
        <Modal
          size="full"
          onClickClose={this.onClickToggleMenu.bind(this, false)}
          visible={this.state.isMenuOpen}>
            <div style={{minHeight: '100%', width: '100%', padding: '15px', backgroundColor: 'white'}}>
              <img className="navbar-logo"  onClick={this.goToRouteAndCloseModal.bind(this, LANDING)} src={LogoPurple}/>
              {this.getNavBarMobileLinks()}
            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    currentUser: state.users.currentUser,
    userData: state.users.user
  });

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        userLogout
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
